import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { bookmarkPost, fetchBookmarkedPosts } from '../../../../services/operations/postDetailsAPI';
import EmptyLoader from '../../../EmptyLoader';
import { formatDate } from '../../../../services/formatDate';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CiBookmarkRemove } from 'react-icons/ci'
import { FaRegBookmark } from "react-icons/fa";


const Bookmarks = () => {

    const navigate = useNavigate();

    const [bookmarks, setBookmarks] = useState([]);
    const [toggled, setToggled] = useState(true);

    const { user } = useSelector((state) => state.profile);
    const { token } = useSelector((state) => state.auth);

    const fetchBookmarks = async () => {
        try{
            const response = await fetchBookmarkedPosts(user._id, token);
            //console.log("FETCH BOOKMARK API", response);
            setBookmarks(response);
        }
        catch(error){
            console.log(error);
        }
    }

    const toggledBookmark = async (postId, userId, token) => {
        try {
            await bookmarkPost(postId, userId, token);  
            
            // Remove the unbookmarked post from bookmarks state
            setBookmarks((prevBookmarks) => prevBookmarks.filter(post => post._id !== postId));
    
            toast.success("Post Unbookmarked");
        } catch (error) {
            console.log(error);
            toast.error("Failed to Unbookmark Post");
        }
    };
    

    useEffect(() => {
        fetchBookmarks();
    }, []);

    return (
        <div className='py-8 px-8 lg:px-[6rem] lg:py-[5rem] overflow-y-auto bg-white min-h-[785px]'>
            <h1 className="text-4xl font-bold overflow-hidden">Bookmarks</h1>
            {
                bookmarks?.length > 0 && 
                    <p className="font-medium text-sm mt-5">{bookmarks?.length} Posts Bookmarked</p>
            }

            {
                bookmarks?.length > 0 ? (
                    <div className='flex flex-wrap w-full gap-3 py-5'>
                        {
                            bookmarks.map((post, index) => (
                                <div key={index} className='w-full md:w-[32%] cursor-pointer bg-white shadow-md relative flex flex-col rounded-xl'>
                                    <img src={post.thumbnail.url} alt={post.postName} loading='lazy'
                                    className='w-full object-cover' onClick={() => navigate(`/posts/${post._id}`)}/>
                                    <div className='p-4'>
                                        <h1 className='font-semibold'>{(post.postName).substring(0, 30)}...</h1>
                                        <p>{formatDate(post.createdAt)}</p>
                                    </div>
                                    <div className='absolute top-2 right-2 rounded-full p-2 bg-white text-black'>
                                        {
                                            toggled ? (
                                                <CiBookmarkRemove onClick={() => {toggledBookmark(post._id, user._id, token); setToggled(!toggled)}} fontSize={24} className='text-red-600'/>
                                            ) : (
                                                <FaRegBookmark onClick={() => {toggledBookmark(post._id, user._id, token); setToggled(!toggled)}} />
                                            )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
                : (
                    <div className="flex flex-col gap-4 items-center justify-center mt-[10rem]">
                        <EmptyLoader />
                        <h1>No posts bookmarked</h1>
                        {/* <Link to={`../dashboard/create-post`} className="bg-[#8D493A] hover:bg-[#75392c] transition-all duration-300
                        px-6 py-2 rounded-lg text-white font-medium">
                            Create New Post
                        </Link> */}
                    </div>
                )
            }
        </div>
    )
};

export default Bookmarks;