import toast from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { postEndpoints } from "../apis";
import { setPinnedPostIds } from "../../slices/postSlice";
import { setLoading } from "../../slices/profileSlice";




const { 
    CREATE_POST_API, 
    EDIT_POST_API,
    DELETE_POST_API,
    CREATE_SECTION_API,
    UPDATE_SECTION_API,
    DELETE_SECTION_API,
    CREATE_SUB_SECTION_API,
    UPDATE_SUB_SECTION_API,
    DELETE_SUB_SECTION_API,
    POST_CATEGORIES_API,
    GET_POST_DETAILS_API,
    GET_POST_DETAILS_TO_VIEW_API,
    PIN_POSTS,
    UNPIN_POSTS,
    SEARCH_POSTS_API,
    LIKE_POST_API,
    UNLIKE_POST_API,
    CREATE_COMMENT_API,
    DELETE_COMMENT_API,
    VIEW_ALL_COMMENTS_API,
    FETCH_ALL_GENERATED_COMMENTS_API,
    BOOKMARK_POST_API,
    FETCH_BOOKMARK_POSTS_API } = postEndpoints;

//create post
export const addPostDetails = async (formData, token) => {
    let result = [];
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", CREATE_POST_API, formData, {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        });
        //console.log("CREATE POST API RESPONSE", response);

        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        result = response?.data?.data;
        toast.success("Post Details Added successfully");
    }
    catch(error){
        //console.log("CREATE POST API ERROR", error);
        toast.error("Failed to create post");
    }
    toast.dismiss(toastId);
    return result;
}

//update post
export const editPostDetails = async (formData, token) => {
    let result = [];
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", EDIT_POST_API, formData, {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        });
        //console.log("UPDATE POST API RESPONSE", response);

        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        result = response?.data?.data;
        toast.success("Post updated successfully");
    }
    catch(error){
        //console.log("UPDATE POST API ERROR", error);
        toast.error("Failed to update post");
    }
    toast.dismiss(toastId);
    return result;
}

//delete post
export const deletePost = async (data, token) => {
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", DELETE_POST_API, data, {
            Authorization: `Bearer ${token}`,
        });
        //console.log("DELETE POST API RESPONSE", response);
        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        toast.success("Post Deleted Successfully");
    }
    catch(error){
        //console.log("DELETE POST API ERROR", error);
        toast.error("Cannot Delete the Post");
    }
    toast.dismiss(toastId);
}

//create a section
export const createSection = async (data, token) => {
    let result = null;
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", CREATE_SECTION_API, data, {
            Authorization: `Bearer ${token}`,
        })
        //console.log("CREATE SECTION API RESPONSE", response);
        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        toast.success("Section created successfully");
        result = response?.data?.data;
    }
    catch(error){
        //console.log("CREATE SECTION API ERROR", error);
        toast.error(error.message);
    }
    toast.dismiss(toastId);
    return result
}

//update a section
export const updateSection = async (data, token) => {
    let result = null
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", UPDATE_SECTION_API, data, {
            Authorization: `Bearer ${token}`,
        })
        //console.log("UPDATE SECTION API RESPONSE", response);
        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        toast.success("Section updated successfully");
        result = response?.data?.data;
    }
    catch(error){
        //console.log("UPDATE SECTION API ERROR", error);
        toast.error(error.message);
    }
    toast.dismiss(toastId);
    return result
}

//delete a section
export const deleteSection = async (data, token) => {
    let result = null
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", DELETE_SECTION_API, data, {
            Authorization: `Bearer ${token}`,
        })
        //console.log("DELETE SECTION API RESPONSE", response);
        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        toast.success("Section deleted successfully");
        result = response?.data?.data;
    }
    catch(error){
        //console.log("DELETE SECTION API ERROR", error);
        toast.error(error.message);
    }
    toast.dismiss(toastId);
    return result
}

//create subSection
export const createSubSection = async (formData, token) => {
    let result = null
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", CREATE_SUB_SECTION_API, formData, {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        })
        //console.log("CREATE SUB SECTION API RESPONSE", response);
        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        toast.success("Sub Section created successfully");
        result = response?.data?.data;
    }
    catch(error){
        //console.log("CREATE SUB SECTION API ERROR", error);
        toast.error(error.message);
    }
    toast.dismiss(toastId);
    return result
}
//update a sub section
export const updateSubSection = async (formData, token) => {
    let result = null
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", UPDATE_SUB_SECTION_API, formData, {
            Authorization: `Bearer ${token}`,
        })
       // console.log("UPDATE SUB SECTION API RESPONSE", response);
        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        toast.success("Sub Section updated successfully");
        result = response?.data?.data;
    }
    catch(error){
        //console.log("UPDATE SUB SECTION API ERROR", error);
        toast.error(error.message);
    }
    toast.dismiss(toastId);
    return result
}

//delete a sub section
export const deleteSubSection = async (data, token) => {
    let result = null
    const toastId = toast.loading("Loading...");
    try{
        const response = await apiConnector("POST", DELETE_SUB_SECTION_API, data, {
            Authorization: `Bearer ${token}`,
        })
        //console.log("DELETE SUB SECTION API RESPONSE", response);
        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        toast.success("Sub Section deleted successfully");
        result = response?.data?.data;
    }
    catch(error){
        //console.log("DELETE SUB SECTION API ERROR", error);
        toast.error(error.message);
    }
    toast.dismiss(toastId);
    return result
}

//fetch the available post categories
export const fetchPostCategories = async () => {
    let result = [];
    try{
        const response = await apiConnector("GET", POST_CATEGORIES_API);
        //console.log("POST CATEGORIES API RESPONSE", response);

        if(!response?.data?.success){
            throw new Error(response.data.message);
        }
        result = response?.data?.allCategory;
        //console.log("Result", result);
    }
    catch(error){
        //console.log("FETCH POST CATEGORIES API ERROR", error);
        toast.error("Failed to display Category")
    }
    return result;
}

//get full post details for editing
export async function getFullDetailsOfPost(postId, token){
    const toastId = toast.loading("Loading...");
    let result = [];
    try{
        const response = await apiConnector("GET", `${GET_POST_DETAILS_API}?postId=${postId}`, null,  {
            Authorization: `Bearer ${token}`,
        });

        //console.log("GET_FULL_POST_DETAILS_API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.data;
        //console.log("RESULT OF API GET FULL DETAILS OF POST", result);
        //toast.success("Post Details successfully");
    }
    catch(error){
        //console.log("GET_ADMIN_POST_DETAILS_API_ERROR", error);
        toast.error("Something went while displaying Posts");
    }
    toast.dismiss(toastId);
    return result
}

//get full post details for viewing
export async function getFullDetailsOfPostToView(postId){
    const toastId = toast.loading("Loading...");
    let result = [];

    try{
        const response = await apiConnector("GET", GET_POST_DETAILS_TO_VIEW_API, null, null, {postId});

        //console.log("GET_FULL_POST_DETAILS_TO_VIEW_API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.data;
        //console.log("RESULT OF API GET FULL DETAILS OF POST", result);
        //toast.success("Post Details successfully");
    }
    catch(error){
        //console.log("GET_POST_DETAILS_API_ERROR", error);
        toast.error("Something went while displaying Posts");
    }
    toast.dismiss(toastId);
    return result
}

//pin a post
export const pinPost = (userId, postId, token) => async (dispatch) => {
    try {
        const response = await apiConnector("POST", PIN_POSTS, { userId, postId }, {
            Authorization: `Bearer ${token}`,
        });

        //console.log("PINNED RESPONSE", response.data.data);

        if (response.data.success) {
            dispatch(setPinnedPostIds(response.data.data));
        }
    } catch (error) {
        toast.error("Failed to pin post");
        //console.error("Pin post error", error);
    }
};

//unpin a post
export const unpinPost = (userId, postId, token) => async (dispatch) => {
    try {
        const response = await apiConnector("POST", UNPIN_POSTS, { userId, postId }, {
            Authorization: `Bearer ${token}`,
        });

        //console.log("UNPINNED RESPONSE", response.data.data);

        if (response.data.success) {
            dispatch(setPinnedPostIds(response.data.data));
        }
    } catch (error) {
        toast.error("Failed to unpin post");
        //console.error("Unpin post error", error);
    }
};

//search Post
export async function searchPost(searchQuery){
    let result = [];
    try{
        const response = await apiConnector("GET", `${SEARCH_POSTS_API}?search=${searchQuery}`);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.allPosts;
    }
    catch(error){
        //console.log("SEARCH POST API ERROR", error);
    }
    return result;
}

//like a post
export async function likePost(postId, token){
    let result;
    try{
        const response = await apiConnector("POST", LIKE_POST_API, {postId}, {
            Authorization: `Bearer ${token}`,
        });

        //console.log("LIKE POST API RESPONSE", response.data);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.success;
        toast.success("Liked");
    }
    catch(error){
        //console.log("LIKE POST API ERROR", error);
    }
    return result;
}

//unlike
export async function unlikePost(postId, token){
    let result;
    try{
        const response = await apiConnector("POST", UNLIKE_POST_API, {postId}, {
            Authorization: `Bearer ${token}`,
        });

        //console.log("UNLIKE POST API RESPONSE", response.data);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.success;
    }
    catch(error){
        //console.log("UNLIKE POST API ERROR", error);
    }
    return result;
}

//create comment
export async function createComment(postId, token, data){
    let result=[];
    try{
        const response = await apiConnector("POST", CREATE_COMMENT_API, {postId, comment: data.comment}, {
            Authorization: `Bearer ${token}`,
        });

        //console.log("CREATE COMMENT API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.data;
        toast.success("Comment created");
    }
    catch(error){
        //console.log("CREATE COMMENT API ERROR", error);
        toast.error("Failed to create comment. Please try again.");
    }
    return result;
}

//delete comment
export async function deleteComment(postId, token, commentId){
    let result;
    try{
        const response = await apiConnector("POST", DELETE_COMMENT_API, {postId, commentId}, {
            Authorization: `Bearer ${token}`,
        });

        //console.log("DELETE COMMENT API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.success;
        toast.success("Comment deleted");
    }
    catch(error){
        //console.log("DELETE COMMENT API ERROR", error);
        toast.error("Failed to delete comment. Please try again.");
    }
    return result;
}

//fetch all comments
export async function getAllComments(postId){
    let result = [];
    try{
        const response = await apiConnector("POST", VIEW_ALL_COMMENTS_API, {postId});

        //console.log("VIEW ALL COMMENTS API RESPONSE", response);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data;
        //console.log("RESULT", result);
    }
    catch(error){
        console.log("VIEW ALL COMMENTS API ERROR", error);
    }
    return result;
}

//fetch all generated comments
export async function fetchAllGeneratedComments(){
    let result = [];
    try{
        const response = await apiConnector("GET", FETCH_ALL_GENERATED_COMMENTS_API);

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data;
    }
    catch(error){
        //console.log("FETCH ALL GENERATED COMMENTS API ERROR", error);
    }
    return result;
}

//bookmark post
export async function bookmarkPost(postId, userId, token){
    const toastId = toast.loading("Loading...");
    let result;
    try{
        const response = await apiConnector("POST", BOOKMARK_POST_API, {postId, userId}, {
            Authorization: `Bearer ${token}`,
        })

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.success;
    }
    catch(error){
        console.log("BOOKMARK POST API ERROR", error);
        toast.error("Failed to bookmark post")
    }
    toast.dismiss(toastId);
    return result;
}

//fetch bookmarked posts
export async function fetchBookmarkedPosts(userId, token){
    const toastId = toast.loading("Loading...");
    let result = [];
    try{
        const response = await apiConnector("POST", FETCH_BOOKMARK_POSTS_API, {userId}, {
            Authorization: `Bearer ${token}`,
        });

        if(!response.data.success){
            throw new Error(response.data.message);
        }

        result = response.data.data;
    }
    catch(error){
        console.log("FETCH BOOKMARKED POSTS API ERROR", error);
    }
    toast.dismiss(toastId);
    return result;
}