import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { useEffect, useState } from 'react';
import Home from './pages/Home';
import Navbar from './components/common/Navbar';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import ForgotPassword from './pages/ForgotPassword';
import UpdatePassword from './pages/UpdatePassword';
import VerifyEmail from './pages/VerifyEmail';
import AboutUs from './pages/AboutUs';
import OpenRoute from './components/core/Auth/OpenRoute';
import ContactUs from './pages/ContactUs';
import Dashboard from './pages/Dashboard';
import MyProfile from './components/core/Dashboard/MyProfile';
import PrivateRoute from './components/core/Auth/PrivateRoute';
import Error from './pages/Error';
import Setting from './components/core/Dashboard/Settings/index';
import UploadedPosts from './components/core/Dashboard/UploadedPosts';
import Draft from './components/core/Dashboard/Draft';
import AddPost from './components/core/Dashboard/AddPost';
import EditPost from './components/core/Dashboard/EditPost';
import Catalog from './pages/Catalog';
import PostDetails from './pages/PostDetails';
import Stories from './pages/Stories';
import StoryDetails from './pages/StoryDetails';
import AddStory from './components/core/Dashboard/AddStory/AddStory';
import UploadedStories from './components/core/Dashboard/UploadedStories';
import AdminDashboard from './components/core/Dashboard/AdminDashboard';
import HelpCenter from './pages/HelpCenter';
import { useDispatch } from 'react-redux';
import { logout } from './services/operations/authAPI';
import { jwtDecode } from "jwt-decode";
import Privacy from './pages/Privacy';
import Tags from './pages/Tags';
import Bookmarks from './components/core/Dashboard/Bookmarks/Bookmarks';
//bg-[#EED9C4]

function App() {

  //for complete post data
  const [data, setData] = useState([]);

  //loader
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  async function fetchData(){
    try{
      //const response = await fetch("https://www.jeviknows.com/api/v1/post/getAdminPosts");
      const response = await fetch("https://jeviknows-server.vercel.app/api/v1/post/getAdminPosts");
      //const response = await fetch("http://localhost:4000/api/v1/post/getAdminPosts");
      const res = await response.json();
      setData(res.data);
      setLoading(false);
      //console.log(res.data);
    }
    catch(error){
      console.log(error);
      setLoading(false);
    }
    
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    if(token){
      try{
        const decodedToken = jwtDecode(token);
        

        const expirationTime = decodedToken.exp;

        if(expirationTime * 1000 < Date.now()){
          dispatch(logout(navigate));
        }
      }
      catch(error){
        console.log("Error decoding token", error);
        dispatch(logout(navigate));
      }
    }
  },[dispatch, navigate]);

  return (
    <div className='w-screen min-h-screen relative flex flex-col bg-[#F8EDE3]'>
      <Navbar />
          <Routes>

            <Route path='/' element={<Home data={data} />} />
            <Route path='catalog/:catalogName' element={<Catalog />} />
            <Route path='/posts/:postId' element={<PostDetails />}/>
            <Route path='/stories' element={<Stories />} />
            <Route path='/stories/:storyId' element={<StoryDetails />} />
            <Route path='/tags' element={<Tags />} />

            <Route 
                path='login' 
                element={
                <OpenRoute>
                  <LoginPage/>
                </OpenRoute>
                }
              />
            <Route 
                path='signup' 
                element={
                <OpenRoute>
                  <SignupPage/>
                </OpenRoute>
                }
              />
            <Route 
                path='forgot-password' 
                element={
                <OpenRoute>
                  <ForgotPassword />
                </OpenRoute>
              } 
            />
            <Route 
                path='update-password/:id' 
                element={
                <OpenRoute>
                  <UpdatePassword />
                </OpenRoute>
              }
            />
            <Route 
                path='verify-email' 
                element={
                <OpenRoute>
                  <VerifyEmail />
                </OpenRoute>
              } 
            />
            <Route 
                path='/about' 
                element={

                  <AboutUs />

              } 
            />
            <Route
                path='/contact'
                element={

                    <ContactUs/>

                }
            />
            <Route
                path='/privacy'
                element={

                    <Privacy/>

                }
            />
            <Route
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
            >
              <Route path='dashboard/my-profile' element={<MyProfile />}/>
              <Route path='dashboard/settings' element={<Setting />} />
              <Route path='dashboard/my-posts' element={<UploadedPosts/>} />
              <Route path='dashboard/draft' element={<Draft />} />
              <Route path='dashboard/bookmarks' element={<Bookmarks />} />
              <Route path='dashboard/create-post' element={<AddPost />} />
              <Route path='dashboard/edit-post/:postId' element={<EditPost />} />
              <Route path='dashboard/create-story' element={<AddStory />} />
              <Route path='dashboard/my-stories' element={<UploadedStories />} />
              <Route path='dashboard/admin' element={<AdminDashboard />} />
              <Route path='dashboard/help' element={<HelpCenter />} />

            </Route>
            
            <Route 
                path='*'
                element={<Error />}
            />
          </Routes>
    </div>
  );
}

export default App;
