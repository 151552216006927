import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getTagsPosts, getTrendingTags } from '../services/operations/pageAndComponentData';
import _ from 'lodash';
import Spinner from '../components/Spinner';
import { useSelector } from 'react-redux';
import { RxCross2 } from "react-icons/rx";


const Tags = () => {

    const navigate = useNavigate();

    const [posts, setPosts] = useState([]);
    const [trendingTags, setTrendingTags] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get("tag") || "");
    const { loading } = useSelector((state) => state.auth);

    // Debounced function to fetch posts
    const fetchPosts = useCallback(
        _.debounce(async (query) => {
            try {
                const response = await getTagsPosts(query);
                // console.log("RESPONSE of getTagsPosts ON FRONTEND", response.data);
                setPosts(response.data);
            } catch (error) {
                console.log(error);
            }
        }, 500), // 500ms delay
        []
    );

    const fetchTrendingTags = async () => {
        try {
            const response = await getTrendingTags();
            // console.log("RESPONSE of getTrendingTags ON FRONTEND", response.data);
            setTrendingTags(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = (e) => {
        let value = e.target.value.trim();
        value = value.startsWith("#") ? value.slice(1) : value; // Remove # if present

        setSearchTerm(value);
        setSearchParams({ tag: value }); // Update URL dynamically
        fetchPosts(value); // Call the debounced function
    };

    const handleTagClick = (tag) => {
        let cleanTag = tag.startsWith("#") ? tag.slice(1) : tag; // Remove # if present

        setSearchTerm(cleanTag); // Update the search bar
        setSearchParams({ tag: cleanTag }); // Update the URL
        fetchPosts(cleanTag); // Fetch posts based on the selected tag
    };


    // Fetch posts when the page loads (if a tag is already in the URL)
    useEffect(() => {
        if (searchTerm) {
            fetchPosts(searchTerm);
        }
        fetchTrendingTags();
    }, []);

    return (
        <div className='w-screen h-auto flex flex-col justify-center'>
            <div className='h-auto w-full px-4 max-w-[480px] sm:max-w-[800px] lg:max-w-[800px] xl:max-w-[1000px] 2xl:max-w-[1500px] mx-auto my-5 flex flex-col'>
                <div className='w-full gap-[2%] space-y-5 lg:space-y-0 xl:space-y-0 2xl:space-y-0 h-full flex flex-col-reverse lg:flex-row 2xl:flex-row'>

                    {/* Section - Search */}
                    <div className='w-full lg:w-[65%] 2xl:w-[65%] flex flex-col p-5 rounded-xl h-fit'>
                        <div className='w-full relative'>
                        <input
                            type="text"
                            placeholder="Search tags..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="w-full outline-none border-none bg-white p-3 pr-10 rounded-xl"
                        />
                        {searchTerm && (
                            <button 
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                onClick={() => {
                                    setSearchTerm("");
                                    setSearchParams({ tag: "" }); // Clear search params
                                    fetchPosts(""); // Fetch all posts
                                }}
                            >
                                <RxCross2 />
                            </button>
                        )}
                        </div>

                        <div>
                            {
                                loading ? (
                                    <div><Spinner /></div>
                                ) :
                                (
                                    posts.length > 0 ? (
                                        <div className='w-full flex flex-wrap mt-5 gap-3 lg:gap-2 p-2 lg:p-0'>
                                            {
                                                posts?.slice(0, 20).map((post, index) => (
                                                    <div key={index} onClick={() => navigate(`/posts/${post._id}`)}
                                                        className='w-full md:w-[32%] bg-white p-3 cursor-pointer flex flex-col lg:my-2 gap-2 rounded-xl hover:shadow-md transition-all duration-300'>
                                                        <img src={post.thumbnail.url} alt={post.postName} loading='lazy'
                                                        className='w-full oject-cover aspect-video rounded-xl'/>
                                                        <h1 className='lg:text-sm xl:text-base font-semibold mt-5 lg:mt-2 xl:mt-4'>{(post.postName).substring(0, 20)}...</h1>
                                                        <div className='flex flex-wrap items-center gap-2'>
                                                            {
                                                                post.tag.map((tag, index) => (
                                                                    <p key={index} className='text-sm lg:text-[10px] xl:text-sm'>{`#${tag},`}</p>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ) : (
                                        <div className='w-full p-5 rounded-xl my-2'>
                                            <h1 className='text-2xl font-bold'>No posts found</h1>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>

                    {/* Section - Tags */}
                    <div className='w-full bg-green-400 lg:w-[33%] 2xl:w-[33%] flex flex-col items-center h-full rounded-xl space-y-2 overflow-hidden'>
                        <div className='w-full bg-white px-[3.5rem] py-[2rem] lg:px-[1rem] lg:py-[1rem] xl:px-[3.5rem] xl:py-[2rem] rounded-xl'>
                            <h1 className='text-2xl font-bold lg:text-center xl:text-left'>Trending Hashtags</h1>
                            <div className='w-full flex flex-col space-y-2 mt-5'>
                                {trendingTags.map((tag, index) => (
                                    <>
                                        <div 
                                            key={index} 
                                            className='flex gap-4 items-center p-3 text-left justify-between hover:bg-gray-100 rounded-lg transition-all duration-300 cursor-pointer'
                                            onClick={() => handleTagClick(tag._id)}
                                        >
                                            <button className='text-black text-left rounded-md'>{`#${tag._id}`}</button>
                                            {
                                                tag.count > 1000 ? (
                                                    <p>{`${(tag.count) / 1000}K Posts`}</p>
                                                ) : (
                                                    <p>{`${tag.count} Posts`}</p>
                                                )
                                            }
                                        </div>
                                        <div className='w-full h-[2px] bg-gray-100'></div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Tags;
