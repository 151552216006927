import React from "react";
import toast from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { catalogData, postEndpoints } from "../apis";

const { CATALOG_PAGE_DATA_API } = catalogData;
const { GET_TAGS_POSTS_API, GET_TRENDING_TAGS_API } = postEndpoints;

export const getCatalogPageData = async (categoryId) => {
    const toastId = toast.loading("Loading...");

    // Check if categoryId is valid
    if (!categoryId ) {
        //toast.error("Invalid category ID");
        toast.dismiss(toastId);
        return { success: false, message: "Invalid category ID" };
    }

    let result = [];
    try {
        // Ensure proper API call
        const response = await apiConnector("POST", CATALOG_PAGE_DATA_API, {
            categoryId: categoryId
        });
        
        if (!response?.data?.success) {
            throw new Error("Could not fetch Category Page Data");
        }

        result = response?.data;
    } catch (error) {
        //console.log("CATALOG PAGE DATA API ERROR", error);
        toast.error("An error occurred");
        result = error.response?.data || { success: false, message: "An error occurred" };
    }
    toast.dismiss(toastId);
    return result;
}

export const getTagsPosts = async (tag) => {
    const toastId = toast.loading("Loading...");

    let result = [];
    try {
        const response = await apiConnector("GET", `${GET_TAGS_POSTS_API}?tag=${tag}`);
        if (!response?.data?.success){
            throw new Error("Could not fetch tag posts");
        }

        //console.log("GET_TAGS_POSTS_API_RESPONSE", response.data);
        result = response?.data;
    }
    catch(error){
        toast.error("An error occurred");
        result = error.response?.data || { success: false, message: "An error occurred" };
    }
    toast.dismiss(toastId);
    return result;
}

export const getTrendingTags = async () => {
    const toastId = toast.loading("Loading...");

    let result = [];
    try {
        const response = await apiConnector("GET", GET_TRENDING_TAGS_API);
        if (!response?.data?.success){
            throw new Error("Could not fetch trending tags");
        }

        //console.log("GET_TRENDING_TAGS_API_RESPONSE", response.data);
        result = response?.data;
    }
    catch(error){
        toast.error("An error occurred");
        result = error.response?.data || { success: false, message: "An error occurred" };
    }
    toast.dismiss(toastId);
    return result;
}