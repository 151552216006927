import { toast } from 'react-hot-toast';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostCategories } from "../../../../../services/operations/postDetailsAPI";
import ChipInput from "./ChipInput";
import IconBtn from "../../../../common/IconBtn";
import { setStep, setPost } from "../../../../../slices/postSlice";
import { addPostDetails } from "../../../../../services/operations/postDetailsAPI";
import { editPostDetails } from "../../../../../services/operations/postDetailsAPI";
import ThumbnailImage from './ThumbnailImage';
import { IoIosArrowForward } from "react-icons/io";


const PostInformationForm = () => {

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch();
    const {step} = useSelector((state) => state.post);
    const {post, editPost} = useSelector((state) => state.post);
    const [loading, setLoading] = useState(false);
    const [postCategories, setPostCategories] = useState([]);
    const {token} = useSelector((state) => state.auth);

    useEffect(() => {
        const getCategories = async () => {
            setLoading(true);
            try {
                const categories = await fetchPostCategories();
                if (Array.isArray(categories) && categories.length > 0) {
                    setPostCategories(categories);
                } else {
                    setPostCategories([]); // Ensure it's an empty array if categories is undefined or not an array
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
                setPostCategories([]); // Ensure it's an empty array in case of an error
            }
            setLoading(false);
        };

        if (editPost) {
            setValue("postTitle", post.postName);
            setValue("postShortDesc", post.postDescription);
            setValue("postTags", post.tag);
            setValue("postCategory", post.category);
            setValue("postImage", post.thumbnailImage);
        }

        getCategories();
    }, [editPost, post, setValue]);  // Added dependencies to useEffect

    const isFormUpdated = () => {

        const currentValues = getValues();
        console.log("Current values", currentValues);
        if(currentValues.postTitle !== post.postName || 
           currentValues.postShortDesc !== post.postDescription ||
           JSON.stringify(currentValues.postTags) !== JSON.stringify(post.tag) ||
           currentValues.postCategory !== post.category._id ||
           currentValues.postImage !== post.thumbnailImage )
            return true;
        else
            return false;
    }

    const onSubmit = async (data) => {
        // Handle form submission
        //console.log("Form Data:", data);

        const formData = new FormData();

        if(editPost){
            if(isFormUpdated()){
                //const formData = new FormData();
            
                formData.append("postId", post._id);
                const currentValues = getValues();

                if(currentValues.postTitle !== post.postName){
                    formData.append("postName", data.postTitle);
                }

                if(currentValues.postShortDesc !== post.postDescription){
                    formData.append("postDescription", data.postShortDesc);
                }

                if(currentValues.postTags.toString() !== post.tag.toString()){
                    formData.append("tag", JSON.stringify(data.postTags));
                }

                if(currentValues.postCategory._id !== post.category._id){
                    formData.append("category", data.postCategory);
                }

                if(currentValues.postImage !== post.thumbnail){
                    formData.append("thumbnailImage", data.postImage);
                }

                setLoading(true);
                const result = await editPostDetails(formData, token);
                //console.log("Updated Post Result", result);
                setLoading(false);
                if(result){
                    dispatch(setStep(2));
                    dispatch(setPost(result));
                }
            }
            else{
                toast.error("No changes made to the post");
            }

            return;
        }

        //else create a new post

        formData.append("postName", data.postTitle);
        formData.append("postDescription", data.postShortDesc);
        formData.append("tag", JSON.stringify(data.postTags));
        formData.append("category", data.postCategory);
        formData.append("thumbnailImage", data.postImage);

        setLoading(true);
        // console.log("FORM DATA AFTER APPEND", formData);
        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        const result = await addPostDetails(formData, token);
        //console.log("New Post Result", result);
        if(result){
            //console.log("STEP", step);
            dispatch(setStep(2));
            dispatch(setPost(result));
        }
        setLoading(false);
    };

    return(
        <>
            <div className="flex items-center gap-4 mt-6">
                <div className="p-2 w-[35px] h-[35px] bg-black text-white rounded-full flex items-center justify-center overflow-hidden"> 
                    1
                </div>
                <p className="text-2xl font-light">Information</p>
            </div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="rounded-lg space-y-8 mt-8"
            >

            <div className="mb-4">
                <label htmlFor="title" className="block font-bold mb-2">
                    Post Title <span className="text-blue-500">*</span>
                </label>
                <input
                    type="text"
                    id="postTitle"
                    className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black"
                    placeholder="Enter Post Title"
                    {...register("postTitle", {required:true})}
                />
                {
                    errors.postTitle && <span className="text-red-500">This field is required</span>
                }
            </div>

            <div className="mb-4">
                <label htmlFor="postShortDesc" className="block font-bold mb-2">
                    Post Description <span className="text-blue-500">*</span>
                </label>
                <textarea
                    id="postShortDesc"
                    className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black resize-none
                    min-h-[140px]"
                    placeholder="The clearer and shorter the better"
                    {...register("postShortDesc", {required:true})}
                ></textarea>
                {
                    errors.postShortDesc && (
                        <span className="text-red-500">This field is required</span>
                    )
                }
            </div>
            
            {/* Upload Component is to be made for this picture task */}
            <ThumbnailImage setValue={setValue} />

            <div className="mb-4">
                <label htmlFor="postCategory" className="block font-bold mb-2">
                    Post Category <span className="text-blue-500">*</span>
                </label>
                <select
                    id="postCategory"
                    defaultValue=""
                    {...register("postCategory", {required:true})}
                    className="w-full rounded-md p-2 focus:outline-none bg-gray-200 text-black"
                >
                    <option value="" disabled>Choose a Category</option>

                    {
                        !loading && postCategories.map((category, index) => (
                            <option key={index} value={category?._id}>
                                {category?.name}
                            </option>
                        ))
                    }
                </select>
                {
                    errors.postCategory && (
                        <span className="text-red-500">This field is required</span>
                    )
                }
            </div>

            {/* create a custom component for handling tag input */}
            <ChipInput
                label="Tags"
                name="postTags"
                placeholder="Press Enter or comma to add a tag. (Don't include #)"
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
            />

            <div className='flex flex-col sm:flex-row justify-end items-center px-2 gap-5 py-2'>
                {
                    editPost && (
                        <button
                            onClick={() => dispatch(setStep(2))}
                            className='bg-slate-200 px-6 py-3 rounded-lg hover:bg-slate-300 transition-all duration-300'
                        >
                            Continue without Saving
                        </button>
                    )
                }

                <IconBtn 
                    text={!editPost ? <>Next <IoIosArrowForward /></> : "Save Changes"}
                    type="submit"
                    onClick={() => handleSubmit(onSubmit)}
                    customClasses={"px-6 py-3 bg-blue-600 hover:bg-blue-700 transition-all duration-300 rounded-lg text-white flex items-center gap-2"}
                />
            </div>
            </form>
        </>
    )
};

export default PostInformationForm;